<template>
  <label v-if="label">{{ label }}</label>
  <select
    class="field"
    v-bind="{
      ...$attrs,
      onChange: $event => {
        $emit('update:modelValue', $event.target.value)
      }
    }"
    :value="modelValue"
  >
    <option
      v-for="option in options"
      :selected="option === modelValue"
      :key="option"
      :value="option"
    >
      {{ option }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    }
  }
}
</script>
